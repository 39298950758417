<template>
  <v-app-bar
    app
    absolute
    color="#6A76AB"
    dark
    shrink-on-scroll
    fade-img-on-scroll
    prominent
    dense
    :src="require('../assets/wie_der_wind.jpg')"
    scroll-target="#scrolling"
  >
    <template #img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
      />
    </template>

    <v-app-bar-nav-icon />

    <v-app-bar-title>Wie Der Wind Checklists</v-app-bar-title>

    <v-spacer />

    <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>

    <template #extension>
      <v-tabs
        v-model="tabOn"
        align-with-title
        @change="emitTabChange"
      >
        <v-tabs-slider color="green" />

        <v-tab
          v-for="tab in tabs"
          :key="tab"
        >
          {{ tab }}
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>

export default {
  name: 'MainHeader',
  data: () => ({
    tabOn: null,
    tabs: [
      'Launching', 'Docking', 'Leaving'
    ]
  }),
  methods: {
    emitTabChange () {
      this.$emit('changeTab', this.tabOn)
    }
  }
}
</script>
