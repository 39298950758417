<template>
  <div>
    <v-btn
      color="teal accent-4"
      @click="clearChecks"
    >
      Start Over
    </v-btn>

    <div
      v-for="(question, index) in questions"
      :key="index"
    >
      <v-checkbox
        v-model="checked"
        :label="question"
        :value="question"
        dense
        @change="isItDone"
      />
    </div>
    <v-dialog
      v-model="showComplete"
      max-width="600"
      transition="dialog-top-transition"
      @input="v => v || clearChecks()"
    >
      <template #default="dialog">
        <v-card>
          <v-card-text>
            <div class="text-h4 pa-1">
              {{ message }}
            </div>
          </v-card-text>
          <video
            v-if="visualType === 'video'"
            :src="require('../assets/' + visual)"
            autoplay
            loop
            width="100%"
          />
          <v-img
            v-if="visualType === 'image'"
            :src="require('../assets/' + visual)"
          />
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog.value = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'CheckList',
  props: {
    questions: {
      type: Array,
      required: true
    },
    visual: {
      type: String,
      required: true
    },
    visualType: {
      type: String,
      required: true
    },
    song: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showComplete: false,
      checked: [],
      sailingSong: {
        audio: new Audio(require('../assets/' + this.song)),
        isPlaying: false
      }
    }
  },
  methods: {
    isItDone () {
      if (this.questions.length === this.checked.length) {
        this.showComplete = true
        this.playSailing()
      }
    },
    clearChecks () {
      this.checked = []
      this.stopSailing()
    },
    playSailing () {
      this.sailingSong.audio.play()
    },
    stopSailing () {
      this.sailingSong.audio.pause()
      this.sailingSong.audio.currentTime = 0
    }
  }
}
</script>

<style>
.v-input--is-label-active label {
  text-decoration: line-through;
}
</style>
