<template>
  <v-app>
    <Main />
  </v-app>
</template>

<script>
import Main from '@/components/LandingPage'

export default {
  name: 'App',

  components: {
    Main
  }

}
</script>
