<template>
  <div>
    <MainHeader @changeTab="changeTab" />
    <v-main>
      <v-sheet
        id="scrolling"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="height: 1000px;">
          <v-tabs-items
            id="scrolling"
            v-model="tabOn"
          >
            <v-tab-item>
              <Checklist
                :questions="launchingQuestions"
                message="Ready to Sail!"
                visual="mike_and_failure_halyard.mp4"
                visual-type="video"
                song="sailing.mp3"
              />
            </v-tab-item>
            <v-tab-item>
              <Checklist
                :questions="dockeringQuestions"
                message="Back at Dock!"
                visual="wendy_at_wheel.jpg"
                visual-type="image"
                song="come_sail_away.mp3"
              />
            </v-tab-item>
            <v-tab-item>
              <Checklist
                :questions="leavingQuestions"
                message="Until Next Time!"
                visual="Heather_and_Daphne.jpg"
                visual-type="image"
                song="dont_rock_my_boat.mp3"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-sheet>
    </v-main>
  </div>
</template>

<script>
import Checklist from '@/components/CheckList'
import MainHeader from '@/components/MainHeader'

export default {
  name: 'LandingPage',
  components: {
    MainHeader,
    Checklist
  },
  data: () => ({
    tabOn: 0,
    tabs: [
      'Launching', 'Docking', 'Leaving'
    ],
    launchingQuestions: require('./questions/launching.json'),
    dockeringQuestions: require('./questions/docking.json'),
    leavingQuestions: require('./questions/leaving.json')
  }),
  methods: {
    changeTab (tabOn) {
      this.tabOn = tabOn
    }
  }
}
</script>

<style scoped>

</style>
